import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import JDALink from 'components/JDALink';
import JDAtsFailModal from 'components/_v2/_common/modals/JDAtsFailModal';
import PositionApplyCancelModal from 'components/_v2/positionJd/PositionApplyCancelModal';
import PositionApplyFirstModal from 'components/_v2/positionJd/PositionApplyFirstModal';
import PositionApplySuccessModal from 'components/_v2/positionJd/PositionApplySuccessModal';
import PositionJdChallengeFailModal from 'components/_v2/positionJd/PositionJdChallengeFailModal';
import PositionJdFirstBookmarkModal from 'components/_v2/positionJd/PositionJdFirstBookmarkModal';
import ProfileApplyModal from 'components/_v2/positionJd/ProfileApplyModal';
import ProfileRequirementModal from 'components/_v2/positionJd/ProfileRequirementModal';
import MatchDashBoardMessageModal from 'components/jobMatch/dashboard/MatchDashBoardMessageModal';
import { JobApplicationProcessType, MatchingOfferStatusType, PositionApplyType } from 'consts/PositionType';
import RoutePaths from 'consts/RoutePaths';
import { IconBookmarkBlackGreen } from 'consts/assets/icons/iconPages';
import shadows from 'consts/style/shadows';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import { IResumeAdditionInfoApplyRq } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import MatchMessageStore from 'models/MatchMessageStore';
import PositionJdModel from 'models/_v2/PositionJdModel';
import ResumeModel from 'models/_v2/ResumeModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import { DateFormatMMDDCCHHMMUnit, getDCount, overDateNow, str2DateTime } from 'utils/DateUtils';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import Loading from 'components/Loading';
import TermsModel from 'models/TermsModel';
import JDApplyRestrictedModal from 'components/_v2/_common/modals/JDApplyRestrictedModal';
import PositionApplyMissionCardModal from './PositionApplyMissionCardModal';

const ApplyFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: fit-content;
  background: ${colors.WHITE_100};
  border-top: 1px solid ${colors.CG_40};
  z-index: 1000;
  padding: 13px 20px calc(env(safe-area-inset-bottom) + 13px);
  ${shadows.base};

  .tooltip {
        padding: 14px;
        position: absolute;
        top: -52px;
        right: 24px;
        width: fit-content;
        height: fit-content;
        font: ${Fonts.B3_Medium};
        color: ${colors.CG_30};
        background-color: ${colors.CG_70};
        border-radius: 4px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    }
`;

const MatchingButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 18px;
  font: ${Fonts.B2_Bold};
  line-height: 22px;
  color: ${colors.WHITE_100};
  border-radius: 4px;
  background: linear-gradient(90deg, #4BD667 0%, #40CCC6 100%);

  &:disabled {
    background: ${colors.CG_40};
    color: ${colors.CG_60};
  }
`;

const ApplyButton = styled.button<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 50px);
  padding: 12px 18px;
  font: ${Fonts.B2_Bold};
  line-height: 22px;
  background: ${(props) => (props.active ? colors.CG_60 : colors.JOBDA_BLACK)}; 
  color: ${colors.WHITE_100};
  border-radius: 4px;

  >a {
    font: ${Fonts.B2_Bold};
    line-height: 22px;
  }

  &:disabled {
    background: ${colors.CG_30};
    color: ${colors.CG_50};
  }

  &[data-disabled=true] {
    background: ${colors.CG_30};
    color: ${colors.CG_50};
  }
`;

const BookMarkFrame = styled.div`
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.CG_50};
    border-radius: 4px;
    margin-right: 4px;

    .bookmark {
        width: 24px;
        height: 24px;
    }
`;

interface IPositionJdApplyProps {
  positionJdModel?: PositionJdModel;
  context?: Context;
  login?: Login;
  matchMessageStore?: MatchMessageStore;
  resumeModel?: ResumeModel;
  termsModel?: TermsModel;
}

const PositionJdApply: FC<IPositionJdApplyProps> = ({ termsModel = new TermsModel(), resumeModel = new ResumeModel(), positionJdModel = new PositionJdModel(), context = new Context(), login = new Login(), matchMessageStore = new MatchMessageStore() }) => {
  const [bookmark, setBookmark] = useState<boolean>(false);
  const history = useHistory();
  const bookmarkYn = positionJdModel.stateInfo?.bookmarkYn;
  const ProcessType = positionJdModel.stateInfo?.jobApplicationProcessType;
  const openYn = positionJdModel.basicInfo?.openYn;
  const closingDateTime = positionJdModel.basicInfo?.closingDateTime;
  const [modals, setModals] = useState({
    applyCancel: false,
    applyMissionCard: false,
    atsFail: false,
    challengeFail: false,
    applyFirst: false,
    bookmarkFirst: false,
    profileApply: false,
    profileRequirement: false,
    applySuccess: false,
    applyRestricted: false,
  });
  const [loading, setLoading] = useState(false);
  const [isOpenTermModal, setIsOpenTermModal] = useState<boolean>(false);

  const openModal = (modalName: string) => {
    setModals((prevModals) => ({ ...prevModals, [modalName]: true }));
  };

  const closeModal = (modalName: string) => {
    setModals((prevModals) => ({ ...prevModals, [modalName]: false }));
  };
  const { setToastObject } = useToast();
  const { pathname } = useLocation();
  const [showTooltip, setShowTooltip] = useState(true);
  const { type } = useQueryParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 3000);

    return () => {
      if (positionJdModel.stateInfo?.matchingOfferStatus === MatchingOfferStatusType.OFFER)
        clearTimeout(timer);
    };
  }, []);

  const setPositionBookMark = async () => {
    try {
      if (!login.userInfo?.id) {
        context.setRedirectUrl(pathname);
        history.push(RoutePaths.login);
        return;
      }

      if (bookmark === false) {
        const firstBookmarkYn = await positionJdModel.saveBookMark();
        if (firstBookmarkYn) {
          openModal('bookmarkFirst');
        } else {
          setToastObject({ isOpen: true, type: 'INFO', message: '[지원현황] > 북마크에 저장되었어요.', subMessage: undefined });
        }
      } else {
        await positionJdModel.deleteBookMark();
        setToastObject({ isOpen: true, type: 'INFO', message: '북마크가 삭제되었습니다.', subMessage: undefined });
      }
      setBookmark(!bookmark);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (bookmarkYn !== undefined)
      setBookmark(bookmarkYn);
  }, [bookmarkYn]);

  useEffect(() => {
    if (type === 'builder' && positionJdModel.positionSn !== 0) {
      handleApply();
    }
  }, [type, positionJdModel.positionSn]);

  const handleApply = async () => {
    // 1. 로그인 체크
    if (!login.userInfo?.id) {
      context.setRedirectUrl(pathname);
      history.push(RoutePaths.login);
      return;
    }
    // 2. 지원하기 상태 검증
    try {
      const result = await positionJdModel.verifyApplyPosition();
      if (result) {
        // 2-1 프로필 필수항복 체크
        await positionJdModel.getRequirementVerification(positionJdModel.positionSn);
        if (positionJdModel.profileRequiredItems) {
          openModal('profileRequirement');
        } else {
          // 2-2 프로필 지원하기 모달
          await positionJdModel.getProfileAdditionInfo(positionJdModel.positionSn);
          positionJdModel.tempAdditionalInfo = null; // 초기화
          openModal('profileApply');
        }
      }
    } catch (e: any) {
      if (e.response.data.errorCode === 'B901') {
        openModal('atsFail');
      } else if (e.response.data.errorCode === 'B902') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '삭제된 포지션 입니다.', subMessage: undefined });
      } else if (e.response.data.errorCode === 'B903') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '종료된 포지션 입니다.', subMessage: undefined });
      } else if (e.response.data.errorCode === 'B904') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '마감된 포지션 입니다.', subMessage: undefined });
      } else if (e.response.data.errorCode === 'B905') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '이미 지원한 포지션 입니다', subMessage: undefined });
      } else if (e.response.data.errorCode === 'B906') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '이미 채용 전형이 진행중인 포지션 입니다.', subMessage: undefined });
      } else if (e.response.data.errorCode === 'B907') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '지원 가능한 횟수를 초과하였습니다.', subMessage: undefined });
      } else if (e.response.data.errorCode === 'B908') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '필요한 프로필 필수 입력 정보가 없습니다.', subMessage: undefined });
      } else if (e.response.data.errorCode === 'B909') {
        openModal('applyRestricted');
      } else if (e.response.data.errorCode === 'B911') {
        openModal('challengeFail');
      } else {
        setToastObject({ isOpen: true, type: 'ERROR', message: '지원에 실패했습니다.', subMessage: undefined });
      }
      console.error(e);
    }
  };

  // const openAtsResume = async () => {
  //   const accessToken = await AuthorizeUtil.getAccessToken();
  //   if (accessToken === null) throw new Error('accessToken is null');
  //   await resumeModel.getPublicKey(positionJdModel.positionSn);
  //   try {
  //     const url = `${resumeModel.url}/writeResume?publicKey=${resumeModel.publicKey}&positionId=${resumeModel.positionId}&jobdaToken=Bearer ${accessToken}`;
  //     windowOpenUtils(url, 'writeResume');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleCancel = async () => {
    try {
      await positionJdModel.deleteApplyPosition();
      if (positionJdModel.positionSn) {
        await positionJdModel.loadPositionDetail(Number(positionJdModel.positionSn));
      }
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '지원이 취소되었습니다.' });
      closeModal('applyCancel');
    } catch (e: any) {
      if (e.response.data.errorCode === 'B908') {
        openModal('atsFail');
      } else if (e.response.data.errorCode === 'B904' || e.response.data.errorCode === 'B901') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '마감된 포지션 입니다.', subMessage: undefined });
      } else if (e.response.data.errorCode === 'B905') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '삭제된 포지션 입니다.', subMessage: undefined });
      } else if (e.response.data.errorCode === 'B903') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '이미 채용 전형이 진행중인 포지션 입니다.', subMessage: undefined });
      } else {
        setToastObject({ isOpen: true, type: 'ERROR', message: '지원 취소에 실패했습니다.', subMessage: undefined });
      }
      console.error(e);
    }
  };

  const profileApply = async (additionInfo: IResumeAdditionInfoApplyRq) => {
    try {
      setLoading(true);
      await positionJdModel.applyPosition(positionJdModel.positionSn, additionInfo.additionInfo);
      await positionJdModel.loadPositionDetail(positionJdModel.positionSn);
      setLoading(false);
      openModal('applySuccess');
    } catch (e: any) {
      console.error(e);
      if (e.response.data.errorCode === 'B908') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '지원 조건이 변경되었습니다. 다시 지원해 주세요.', subMessage: undefined, duration: 3000 });
        positionJdModel.tempAdditionalInfo = null; // 추가정보 초기화
      } else if (e.response.data.errorCode === 'B909') {
        openModal('applyRestricted');
      } else {
        setToastObject({ isOpen: true, type: 'ERROR', message: '지원에 실패했습니다.', subMessage: undefined });
      }
      setLoading(false);
    }
    closeModal('profileApply');
  };

  const closeYn = () => {
    if (closingDateTime && getDCount(closingDateTime) > 0) return true;
    return false;
  };

  // 지원 마감이거나 지원 종료
  const positionTerminateYn = !openYn || closeYn();

  const agreeBeforeCancel = async () => {
    openModal('applyCancel');
  };

  const ApplyConditionButton = () => {
    // Matching 매치 제안 받은 포지션인 경우 (포지션 마감시에도 잡다 매치 버튼은 유지) 응답 기한이 안지났으면 지원 가능
    if (positionJdModel.stateInfo?.matchingOfferStatus) {
      if (positionJdModel.stateInfo.matchingOfferStatus === MatchingOfferStatusType.OFFER && !overDateNow(positionJdModel.stateInfo.decisionDueDateTime)) {
        return (
          <MatchingButton
            onClick={() => {
              matchMessageStore.open(Number(positionJdModel.positionSn));
            }}
          >
            MATCHING 수락하기
          </MatchingButton>
        );
      } if (positionJdModel.stateInfo.matchingOfferStatus === MatchingOfferStatusType.ACCEPTED) {
        return (
          <MatchingButton
            disabled
            onClick={() => {
              matchMessageStore.open(Number(positionJdModel.positionSn));
            }}
          >
            MATCHING 수락 완료
          </MatchingButton>
        );
      }
    }

    // JF3 공고면 지원여부 상관없이 홈페이지 지원하기.
    if (positionJdModel.externalInfo) {
      return (
        <ApplyButton
          onClick={() => {
            const url = positionJdModel.externalInfo ? positionJdModel.externalInfo.url : '';
            windowOpenUtils(url, 'jf3');
          }}
          disabled={!overDateNow(positionJdModel.externalInfo?.startDateTime)}
        >
          { overDateNow(positionJdModel.externalInfo?.startDateTime) ? '홈페이지 지원하기' : `${DateFormatMMDDCCHHMMUnit(positionJdModel.externalInfo.startDateTime)} OPEN` }
        </ApplyButton>
      );
    }
    if (ProcessType === JobApplicationProcessType.APPLY) {
      return (
        <ApplyButton
          data-disabled={positionTerminateYn}
          onClick={() => {
            if (positionTerminateYn) {
              setToastObject({ isOpen: true, type: 'ERROR', message: '전형이 진행 된 경우나 지원 마감 시에는 취소가 불가능 합니다.', subMessage: undefined });
            } else {
              agreeBeforeCancel();
            }
          }}
          active
        >
          지원취소
        </ApplyButton>
      );
    }
    // ATS
    if (positionJdModel.basicInfo && positionJdModel.basicInfo.openingDateTime) {
      if (!overDateNow(positionJdModel.basicInfo?.openingDateTime)) {
        return (
          <ApplyButton
            disabled
          >
            {`${DateFormatMMDDCCHHMMUnit(positionJdModel.basicInfo?.openingDateTime)} OPEN` }
          </ApplyButton>
        );
      }
    }
    if (positionJdModel.basicInfo?.applyType === PositionApplyType.CMS) {
      return (
        <ApplyButton>
          <JDALink to={positionJdModel.basicInfo.recruitmentUrl}>홈페이지 지원하기</JDALink>
        </ApplyButton>
      );
    }
    switch (ProcessType) {
      case JobApplicationProcessType.INTERVIEW_OFFERED:
      case JobApplicationProcessType.ACC_REQUEST:
      case JobApplicationProcessType.ACC_SUBMIT:
      case JobApplicationProcessType.PHS_REQUEST:
      case JobApplicationProcessType.PHS_SUBMIT:
        return (
          <ApplyButton
            data-disabled
            onClick={() => {
              setToastObject({ isOpen: true, type: 'ERROR', message: '전형이 진행 된 경우나 지원 마감 시에는 취소가 불가능 합니다.', subMessage: undefined });
            }}
            active
          >
            지원 취소
          </ApplyButton>
        );
      case JobApplicationProcessType.INTERVIEW_ACCEPTED:
      case JobApplicationProcessType.INTERVIEW_DENIED:
      case JobApplicationProcessType.INTERVIEW_PASSED:
      case JobApplicationProcessType.INTERVIEW_FAILED:
        return (
          <ApplyButton
            disabled
            active
            onClick={() => {
              if (positionTerminateYn) {
                setToastObject({ isOpen: true, type: 'ERROR', message: '전형이 진행 된 경우나 지원 마감 시에는 취소가 불가능 합니다.', subMessage: undefined });
              } else {
                agreeBeforeCancel();
              }
            }}
          >
            지원 취소
          </ApplyButton>
        );
      default:
        return (
          <ApplyButton
            disabled={positionTerminateYn}
            onClick={() => handleApply()}
          >
            {positionTerminateYn ? '지원 마감' : `지원하기`}
          </ApplyButton>
        );
    }
  };

  return (
    <>
      <ApplyFrame>
        <BookMarkFrame>
          <IconBookmarkBlackGreen active={bookmark} className='bookmark' onClick={() => setPositionBookMark()} />
        </BookMarkFrame>
        {(showTooltip && positionJdModel.stateInfo?.matchingOfferStatus === MatchingOfferStatusType.OFFER) && (
        <div className='tooltip'>잡다매칭을 통해 제안받은 포지션입니다.<br />이 포지션에 지원하면 인사담당자가 우선 검토해요.</div>
        )}
        <ApplyConditionButton />
      </ApplyFrame>
      <PositionApplyCancelModal isOpen={modals.applyCancel} onSuccess={() => handleCancel()} onClickClose={() => closeModal('applyCancel')} />
      <PositionApplyMissionCardModal isOpen={modals.applyMissionCard} onClickClose={() => closeModal('applyMissionCard')} />
      <JDAtsFailModal isOpen={modals.atsFail} onClickClose={() => closeModal('atsFail')} />
      <JDApplyRestrictedModal isOpen={modals.applyRestricted} onClickClose={() => closeModal('applyRestricted')} />
      <PositionApplyFirstModal isOpen={modals.applyFirst} onClickClose={() => closeModal('applyFirst')} />
      <PositionJdFirstBookmarkModal isOpen={modals.bookmarkFirst} onClickClose={() => closeModal('bookmarkFirst')} />
      <PositionJdChallengeFailModal
        challengeSn={positionJdModel.additionalInfo?.challenge?.challengeSn}
        challengeName={positionJdModel.additionalInfo?.challenge?.challengeName}
        isOpen={modals.challengeFail}
        onClickClose={() => closeModal('challengeFail')}
      />
      {modals.profileApply && (
        <ProfileApplyModal
          handleSuccess={async (additionInfo: IResumeAdditionInfoApplyRq) => {
            profileApply(additionInfo);
          }}
          handleClose={() => closeModal('profileApply')}
        />
      )}
      {modals.profileRequirement && (
      <ProfileRequirementModal
        handleClose={() => closeModal('profileRequirement')}
      />
      )}
      <PositionApplySuccessModal
        isOpen={modals.applySuccess}
        onClickClose={() => closeModal('applySuccess')}
        positionName={positionJdModel.basicInfo?.positionName || ''}
        companyName={positionJdModel.companyInfo?.companyName || ''}
        positionSn={positionJdModel.positionSn}
      />
      {loading && <Loading />}
      <MatchDashBoardMessageModal />
    </>
  );
};

export default inject(injectStore.login, injectStore.positionJdModel, injectStore.context, injectStore.matchMessageStore, injectStore.resumeModel, injectStore.termsModel)(observer(PositionJdApply));
